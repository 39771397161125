//Scroll suave com rolagem ao clicar em qualquer link cujo href contenha '#'
document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
            behavior: "smooth",
        });
    });
});

//Diminuição da imagem de logo durante o scroll
sizingLogoHome = function () {
    // Get the real width of the logo image
    var theLogo = $("#div_imgHomeLogo img");
    var newImage = new Image();
    newImage.src = theLogo.attr("src");
    var imgWidth = 300;

    // distance over which zoom effect takes place
    var maxScrollDistance = 500;

    // set to window height if larger
    maxScrollDistance = Math.min(maxScrollDistance, $(window).height());

    // width at maximum zoom out (i.e. when window has scrolled maxScrollDistance)
    var widthAtMax = 0;

    // calculate diff and how many pixels to zoom per pixel scrolled
    var widthDiff = imgWidth - widthAtMax;
    var pixelsPerScroll = widthDiff / maxScrollDistance;

    $(window).scroll(function () {
        // the currently scrolled-to position - max-out at maxScrollDistance
        var scrollTopPos = Math.min($(document).scrollTop(), maxScrollDistance);

        // how many pixels to adjust by
        var scrollChangePx = Math.floor(scrollTopPos * pixelsPerScroll);

        // calculate the new width
        var zoomedWidth = imgWidth - scrollChangePx;

        // set the width
        $(".img-home-logo img").css("width", zoomedWidth);
    });
};
sizingLogoHome();

//Diminuição da imagem de logo durante o scroll
logoSize = function () {
    // Get the real width of the logo image
    var theLogo = $("#section-header img");
    var newImage = new Image();
    newImage.src = theLogo.attr("src");
    var imgWidth = 0;

    // distance over which zoom effect takes place
    var maxScrollDistance = 500;

    // set to window height if larger
    maxScrollDistance = Math.min(maxScrollDistance, $(window).height());

    // width at maximum zoom out (i.e. when window has scrolled maxScrollDistance)
    var widthAtMax = 45;

    // calculate diff and how many pixels to zoom per pixel scrolled
    var widthDiff = imgWidth - widthAtMax;
    var pixelsPerScroll = widthDiff / maxScrollDistance;

    $(window).scroll(function () {
        // the currently scrolled-to position - max-out at maxScrollDistance
        var scrollTopPos = Math.min($(document).scrollTop(), maxScrollDistance);

        // how many pixels to adjust by
        var scrollChangePx = Math.floor(scrollTopPos * pixelsPerScroll);

        // calculate the new width
        var zoomedWidth = imgWidth - scrollChangePx;

        // set the width
        $("#section-header img").css("width", zoomedWidth);
        $("#section-header img").css("height", zoomedWidth);
    });
};
logoSize();

//Inicializar o OwlCarousel

//Inicializar o sticky do cabeçalho da subseção Profissionais
// $(document).ready(function () {
//     var stickyActive = false;
//     $(window).scroll(function () {
//         if ($(window).scrollTop() + $(window).height() == $(document).height()) {
//             $(".subsection-team .subsection-header").trigger("sticky_kit:detach");
//             stickyActive = false;
//         } else if (!stickyActive && $(window).scrollTop() + $(window).height() < $(document).height() - 100) {
//             stickyActive = true;
//             $(".subsection-team .subsection-header").stick_in_parent({
//                 offset_top: $(".navbar").outerHeight(true),
//                 sticky_class: "stickit",
//             });
//         }
//     });
// });

/**
 * Remove a formatação de um texto. Caracteres retirados => .,/-_)(
 *
 * @param {String} strText texto a ser limpado
 * @returns {String}
 */
function removeFormat(strText) {
    return strText.replace(/(\.|\/|-| |[(]|[)]|_)/g, "");
}

/**
 * Verifica se o email informado é válido
 *
 * @param {String} email
 * @returns {boolean}
 */
function isValidEmail(email) {
    var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-]{3,})+\.)+([a-zA-Z0-9]{2,4})+$/;
    return re.test(String(email).toLowerCase());
}

function notifySuccess(msg) {
    notify({
        message: msg,
        classes: "alert-success",
        templateUrl: "",
        position: "right",
        duration: 4000,
    });
}

function notifyError(msg) {
    notify({
        message: msg,
        classes: "alert-danger",
        templateUrl: "",
        position: "right",
        duration: 5000,
    });
}
